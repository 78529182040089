<template>
    <div id="start" >
        <div id="shop" @click="goToShop" ref="shop"   >
            <div id="enter"></div>
            <div id="shop-hand" ref="shopHand"  ></div>
        </div>
        <div id="hover_bg"></div>
    </div>

</template>

<script>

    export default {
        name: 'Start',
        data(){
          return {
              window: {
                  width: 0,
                  height: 0
              },
          }
        },
        watch: {
          $route(){
              this.$store.dispatch('makeLoaderInactive')
                  .then(() => {
                  });
            }
        },
        created() {
            window.addEventListener('resize', this.handleResize);
            this.handleResize();
        },
        mounted() {
            this.$store.dispatch('makeLoaderInactive')
                .then(() => {
                });

        },
        destroyed() {
            window.removeEventListener('resize', this.handleResize);
        },
        methods: {
            handleResize() {
                this.window.width = window.innerWidth;
                this.window.height = window.innerHeight;
            },
            goToShop(){

                if( window.innerWidth < 992 && !this.$refs.shop.classList.contains('active') ){
                    this.$refs.shop.classList.add('active');
                }else{
                    if( this.$store.getters.getLang === 'de'){
                        this.$router.push({name: 'Store'});
                    }else{
                        this.$router.push({name: 'StoreENG'});
                    }
                }


            }
        },
        beforeRouteLeave(to, from, next){
            this.$store.dispatch('makeLoaderActive')
                .then( () => next() );
        }
    }

</script>

<style lang="less">
    @ratio: 1;

    #start{
        position: relative;
        width: 100vw;
        height: 100vh;
        background: url("../assets/images/start_bg.jpg") bottom center no-repeat #c8d3e0;
    }
    #hover_bg{
        width: 100%;
        height: 100%;
        background: rgba(0,0,0, 0.65);

        opacity: 0;

        transition: 0.3s all ease-in-out;
    }

    #shop{
        position: absolute;
        z-index: 2;
        bottom: 40px;
        left: 50%;


        width: 580px * @ratio;
        height: 413px * @ratio;

        transform: translateX(-267px);

        background: url("../assets/images/shop.png") center center no-repeat;
        background-size: cover;

        cursor: grab;

        &:hover{
            #enter{
                opacity: 1;
            }

            & + #hover_bg{
                opacity: 1 !important;
            }
        }

        .pos{
            position: relative;
            width: 100%;
            height: 100%;
            cursor: none;
        }

        #enter{
            position: absolute;
            top: -120px;
            left: 50%;

            transform: translateX(-50%);

            width: 317px;
            height: 77px;

            background: url("../assets/images/enter.png") center center no-repeat;
            background-size: cover;

            opacity: 0;

            transition: 0.3s all ease-in-out;
        }



        .make(){
            from{
                -webkit-transform: rotate3d(0, 0, 1, 0) translateX(0px);
                transform: rotate3d(0, 0, 1, 0) translateX(0px);
            }

            25% {
                -webkit-transform: rotate3d(0, 0, 1, -10deg) translateX(-25px);
                transform: rotate3d(0, 0, 1, -10deg) translateX(-25px);
            }

            50% {
                -webkit-transform: rotate3d(0, 0, 1, 0) translateX(0px);
                transform: rotate3d(0, 0, 1, 0) translateX(0px);
            }

            75% {
                -webkit-transform: rotate3d(0, 0, 1, 10deg) translateX(25px);
                transform: rotate3d(0, 0, 1, 10deg) translateX(25px);
            }

            to {
                -webkit-transform: rotate3d(0, 0, 1, 0deg) translateX(0px);
                transform: rotate3d(0, 0, 1, 0deg) translateX(0px);
            }
        }
        @-webkit-keyframes shop-hand {
            .make();
        }

        @keyframes shop-hand{
            .make();
        }

    }

    #shop-hand{
        position: absolute;
        right: -160px;
        bottom: 130px;

        width: 263px * @ratio;
        height: 137px * @ratio;

        background: url(../assets/images/shop-hand.png) center center no-repeat;
        background-size: cover;

        cursor:none;

        -webkit-transform-origin: top center;
        transform-origin: top center;
        -webkit-animation-name: shop-hand;
        animation-name: shop-hand;


        -webkit-animation-duration: 1.5s;
        animation-duration: 1.5s;
        -webkit-animation-fill-mode: forwards;
        animation-fill-mode: forwards;
        -webkit-animation-delay: 0.2s;
        animation-delay: 0.2s;
        --webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
        --webkit-animation-timing-function: linear;
        animation-timing-function: linear;

    }
</style>
