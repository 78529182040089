<template>
    <div id="store" :class="{ store_en: getLang === 'en', store_de: getLang === 'de'}">
        <div id="hover" ref="hover" @click="closeOverBg"></div>
        <div id="flaschen-regal"
             class="clickbar-object"
             data-ref="infobox-flaschen-regal"
             @mouseover="mouseOverEffect"
             @mousemove="mouseOverEffect"
             @mouseleave="mouseLeaveEffect"
             @click="goToLink( $event, getLang === 'de' ? { name: 'About' } : { name: 'AboutENG' } )"
             @dblclick="goToLinkDBC( getLang === 'de' ? { name: 'About' } : { name: 'AboutENG' })">
        </div>
        <div id="flaschen-top-left"></div>
        <div id="flaschen-top-right"></div>
        <div id="flaschen-pine-regal"></div>
        <div id="buecher"></div>
        <div id="buch"
             class="clickbar-object"
             data-ref="infobox-buch"
             @mouseover="mouseOverEffect"
             @mousemove="mouseOverEffect"
             @mouseleave="mouseLeaveEffect"
             @click="goToLink( $event, getLang === 'de' ? { name: 'Sprechstunde' } : { name: 'SprechstundeENG' })"
             @dblclick="goToLinkDBC( $event, getLang === 'de' ? { name: 'Sprechstunde' } : { name: 'SprechstundeENG' })"
        >
        </div>
        <div id="flasche-waage"
             class="clickbar-object"
             data-ref="infobox-flasche-waage"
             @mouseover="mouseOverEffect"
             @mousemove="mouseOverEffect"
             @mouseleave="mouseLeaveEffect"
             @click="goToLink( $event, getLang === 'de' ? { name: 'About', query: { 'produkt': 'elixir'} } : { name: 'AboutENG', query: { 'produkt': 'elixir'} } )"
             @dblclick="goToLinkDBC( getLang === 'de' ? { name: 'About', query: { 'produkt': 'elixir'} } : { name: 'AboutENG', query: { 'produkt': 'elixir'} })">
        </div>
        <div id="waage"></div>
        <div id="glock"
             class="clickbar-object"
             data-ref="infobox-glock"
             @mouseover="mouseOverEffect"
             @mousemove="mouseOverEffect"
             @mouseleave="mouseLeaveEffect"
             @click="goToLink( $event, getLang === 'de' ? { name: 'Kontakt' } : { name: 'KontaktENG' })"
             @dblclick="goToLinkDBC( getLang === 'de' ? { name: 'Kontakt' } : { name: 'KontaktENG' })">

        </div>
        <div id="kiste"
             class="clickbar-object"
             data-ref="infobox-kiste"
             @mouseover="mouseOverEffect"
             @mousemove="mouseOverEffect"
             @mouseleave="mouseLeaveEffect"
             @click="goToLink( $event, getLang === 'de' ? { name: 'Termine' } : { name: 'EventsENG' } )"
             @dblclick="goToLinkDBC( getLang === 'de' ? { name: 'Termine' } : { name: 'EventsENG' })">
        </div>
        <div id="flasche-tisch"
             class="clickbar-object"
             data-ref="infobox-flasche-tisch"
             @mouseover="mouseOverEffect"
             @mousemove="mouseOverEffect"
             @mouseleave="mouseLeaveEffect"
             @click="goToLink( $event, getLang === 'de' ? { name: 'About', query: { 'produkt': 'spiced'} } : { name: 'AboutENG', query: { 'produkt': 'spiced'} } )"
             @dblclick="goToLinkDBC( getLang === 'de' ? { name: 'About', query: { 'produkt': 'spiced'} } : { name: 'AboutENG', query: { 'produkt': 'spiced'} } )">

        </div>
        <div id="flasche-pine-tisch"
             class="clickbar-object"
             data-ref="infobox-flasche-pine-tisch"
             @mouseover="mouseOverEffect"
             @mousemove="mouseOverEffect"
             @mouseleave="mouseLeaveEffect"
             @click="goToLink( $event, getLang === 'de' ? { name: 'About', query: { 'produkt': 'pineapple'} } : { name: 'AboutENG', query: { 'produkt': 'pineapple'} } )"
             @dblclick="goToLinkDBC( getLang === 'de' ? { name: 'About', query: { 'produkt': 'pineapple'} } : { name: 'AboutENG', query: { 'produkt': 'pineapple'} } )">

        </div>
        <div id="kasse"
             class="clickbar-object"
             data-ref="infobox-kasse"
             @mouseover="mouseOverEffect"
             @mousemove="mouseOverEffect"
             @mouseleave="mouseLeaveEffect"
             @click="goToLink( $event, getLang === 'de' ? { name: 'Buy' } : { name: 'BuyENG' })"
             @dblclick="goToLinkDBC( getLang === 'de' ? { name: 'Buy' } : { name: 'BuyENG' })">

        </div>
        <div id="store-tisch"></div>

        <!-- infboxes-->
        <div id="infobox-kiste" class="infobox right" ref="infobox-kiste" @click="closeOverBg">
            <div class="ib-content">
                <div class="ib-title">{{ termine[getLang].title }}</div>
                <div class="ib-inhalt">{{ termine[getLang].inhalt }}</div>
            </div>
            <div class="kr-line"></div>
        </div>

        <div id="infobox-glock" class="infobox right" ref="infobox-glock" @click="closeOverBg">
            <div class="ib-content">
                <div class="ib-title">{{ glock[getLang].title }}</div>
                <div class="ib-inhalt">{{ glock[getLang].inhalt }}</div>
            </div>
            <div class="kr-line"></div>
        </div>

        <div id="infobox-kasse" class="infobox right" ref="infobox-kasse" @click="closeOverBg">
            <div class="ib-content">
                <div class="ib-title">{{ kasse[getLang].title }}</div>
                <div class="ib-inhalt">{{ kasse[getLang].inhalt }}</div>
            </div>
            <div class="kr-line"></div>
        </div>

        <div id="infobox-flasche-tisch" class="infobox right" ref="infobox-flasche-tisch" @click="closeOverBg">
            <div class="ib-content">
                <div class="ib-title">{{ aboutSpiced[getLang].title }}</div>
                <div class="ib-inhalt">{{ aboutSpiced[getLang].inhalt }}</div>
            </div>
            <div class="kr-line"></div>
        </div>

        <div id="infobox-flasche-pine-tisch" class="infobox left" ref="infobox-flasche-pine-tisch" @click="closeOverBg">
            <div class="ib-content">
                <div class="ib-title">{{ aboutPine[getLang].title }}</div>
                <div class="ib-inhalt">{{ aboutPine[getLang].inhalt }}</div>
            </div>
            <div class="kr-line"></div>
        </div>

        <div id="infobox-buch" class="infobox left" ref="infobox-buch" @click="closeOverBg">
            <div class="ib-content">
                <div class="ib-title"><span>{{ sprechstunde[getLang].title }}</span></div>
                <div class="ib-inhalt">{{ sprechstunde[getLang].inhalt }}</div>
            </div>
            <div class="kr-line"></div>
        </div>

        <div id="infobox-flasche-waage" class="infobox left" ref="infobox-flasche-waage" @click="closeOverBg">
            <div class="ib-content">
                <div class="ib-title">{{ aboutElixir[getLang].title }}</div>
                <div class="ib-inhalt">{{ aboutElixir[getLang].inhalt }}</div>
            </div>
            <div class="kr-line"></div>
        </div>

        <div id="infobox-flaschen-regal" class="infobox down" ref="infobox-flaschen-regal" @click="closeOverBg">
            <div class="ib-content">
                <div class="ib-inhalt">{{ about[getLang].inhalt }}</div>
                <div class="ib-title">{{ about[getLang].title }}</div>
            </div>
            <div class="kr-line"></div>
        </div>


    </div>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: 'StoreView',
        metaInfo: {
            title: 'Store',
        },
        data(){
          return {
              browserWidth: 992,
              sprechstunde:{
                  de: {
                      title: "sprechstunde",
                      inhalt: "Lasse dir auf Dich abgestimmte Remedy-Rezepte zusammenstellen..."
                  },
                  en: {
                      title: "consultation hours",
                      inhalt: "Get customized remedy recipes put together for you."
                  }
              },
              glock: {
                  de: {
                      title: "kontakt",
                      inhalt: "Jetzt Kontakt aufnehmen..."
                  },
                  en:{
                      title: "Contact",
                      inhalt: "Make contact..."
                  }
              },
              termine:{
                  de: {
                      title: "Termine",
                      inhalt: "Verpasse keine Events mehr..."
                  },
                  en: {
                      title: "EVENTS",
                      inhalt: "Don't miss any events"
                  }
              },
              kasse: {
                  de: {
                      title: "ONLINE kAUFEN",
                      inhalt: "Remedy jetzt online kaufen!"
                  },
                  en: {
                      title: "BUY ONLINE",
                      inhalt: "Remedy buy now online!"
                  },
              },
              about:{
                  de: {
                      title: "About remedy",
                      inhalt: "Erfahre alles über die Geschichte oder die Produkte..."
                  },
                  en: {
                      title: "About remedy",
                      inhalt: "Learn all about the story or the products."
                  }
              },
              aboutSpiced:{
                  de: {
                      title: "About remedy spiced rum",
                      inhalt: ""
                  },
                  en: {
                      title: "About remedy spiced rum",
                      inhalt: ""
                  }
              },
              aboutElixir:{
                  de: {
                      title: "About remedy elixir",
                      inhalt: ""
                  },
                  en: {
                      title: "About remedy elixir",
                      inhalt: ""
                  }
              },
              aboutPine:{
                  de: {
                      title: "About remedy pineapple",
                      inhalt: ""
                  },
                  en: {
                      title: "About remedy pineapple",
                      inhalt: ""
                  }
              },
              hideHoverLayer: false
          }
        },
        mounted() {
            this.$store.dispatch('makeLoaderInactive')
                .then( () => {});
        },
        computed: {
          ...mapGetters(['getLang'])
        },
        methods: {
            closeOverBg( event ){
                if( window.innerWidth < this.browserWidth ){
                    this.leaveEffect( event );
                }
            },
            mouseOverEffect( event ){
                if( window.innerWidth >= this.browserWidth ) {
                    this.overEffect(event);
                }
            },
            mouseLeaveEffect(){
                if( window.innerWidth >= this.browserWidth ) {
                    this.leaveEffect();
                }
            },
            overEffect( event ){

                if(   this.hideHoverLayer === false ){
                    event.target.style.zIndex = 10;
                    event.target.classList.add('active');
                    this.$refs.hover.classList.add('index7');
                    this.$refs.hover.classList.add('active');

                    if( event.target.getAttribute('data-ref') ){
                        this.$refs[event.target.getAttribute('data-ref')].classList.add('active');
                    }
                }
            },
            leaveEffect(  ){ //event

                this.hideHoverLayer = true;

                this.$el.getElementsByClassName('clickbar-object')
                    .forEach( element => {
                        element.style.zIndex = '';
                        element.classList.remove('active');
                    });

                // event.target.style.zIndex = '';
                // event.target.classList.remove('active');
                this.$refs.hover.classList.remove('active');

                // if( event.target.getAttribute('data-ref') ){
                //     this.$refs[event.target.getAttribute('data-ref')].classList.remove('active');
                //
                // }

                this.$el.getElementsByClassName('infobox')
                    .forEach( element => {
                        element.classList.remove('active');
                    });

                setTimeout( () => {
                    this.$refs.hover.classList.remove('index7');
                    this.hideHoverLayer = false;
                }, 150);

            },
            goToLink( event, name ){

                if( (window.innerWidth < this.browserWidth) && !event.target.classList.contains('active') ){
                    this.overEffect( event );
                }else{
                    this.$router.push( name)
                }
            },
            goToLinkDBC(name ){
                this.$router.push( name)
            }
        },
        beforeRouteLeave(to, from, next){
            this.$store.dispatch('makeLoaderActive')
                .then( () => next() );
        }
    }
</script>

<style lang="less" scoped>
    @import "../assets/less/vars";

    @ratio: 0.8;
    @ratioMove: @ratio;

    #store{
        position: relative;
        height: 100vh;
        width: 100vw;

        background: url('../assets/images/store-bg.jpg') center top no-repeat;


        &.store_en{
            #buch{
                background-image: url("../assets/images/buch_en.png");
            }
            #kiste{
                background-image: url("../assets/images/kiste_en.png");
            }
        }

    }

    #hover{
        position: relative;
        width: 100%;
        height: 100%;

        background: rgba(0,0,0, 0.66);
        opacity: 0;

        transition: 0.2s all ease-in-out;

        &.index7{
            z-index: 7;
        }
        &.active{
            opacity: 1;
        }
    }

    #store-tisch{
        position: absolute;
        bottom: 0;
        left: 0;

        width: 100%;
        height: 212px * @ratio;

        background: url("../assets/images/store-tisch.jpg") bottom center no-repeat;
        background-size: cover;

        z-index: 2;
    }

    .clickbar-object{
        cursor: grab;

        &:hover{
            .infobox{
                display: flex;
            }
        }
    }

    #glock{
        position: absolute;
        left:50%;
        bottom: 60px * @ratio;

        margin-left: 75px * @ratioMove;

        width: 161px * @ratio;
        height: 151px * @ratio;

        z-index: 5;

        background: url("../assets/images/glocke.png") center center no-repeat;
        background-size: cover;

    }

    #kiste{
        position: absolute;
        left:50%;
        bottom: 18px * @ratio;

        margin-left: 215px * @ratioMove;

        width: 417px * @ratio;
        height: 334px * @ratio;

        z-index:4;

        background: url("../assets/images/kiste.png") center center no-repeat;
        background-size: cover;

        webkit-clip-path: polygon(76% 98%, 100% 68%, 99% 1%, 28% 0%, 26% 20%, 19% 40%, 0% 45%, 1% 78%);
        clip-path: polygon(76% 98%, 100% 68%, 99% 1%, 28% 0%, 26% 20%, 19% 40%, 0% 45%, 1% 78%);
    }

    #flasche-tisch{
        position: absolute;
        left:50%;
        bottom: 138px * @ratio;

        margin-left: 225px * @ratioMove;

        width: 151px * @ratio;
        height: 284px * @ratio;

        z-index:3;

        background: url("../assets/images/flasche-tisch.png") center center no-repeat;
        background-size: cover;

        -webkit-clip-path: polygon(1% 78%, 58% 70%, 71% 25%, 100% 25%, 68% 0%, 32% 1%, 0% 28%);
        clip-path: polygon(1% 78%, 58% 70%, 71% 25%, 100% 25%, 68% 0%, 32% 1%, 0% 28%);

    }

    #kasse{
        position: absolute;
        left:50%;
        bottom: 99px * @ratio;

        margin-left: 391px * @ratioMove;

        width: 463px * @ratio;
        height: 437px * @ratio;

        z-index:3;

        background: url("../assets/images/kasse.png") center center no-repeat;
        background-size: cover;


        -webkit-clip-path: polygon(43.5% 100%, 98% 91%, 100% 27%, 95% 13%, 87% 3%, 22% 2%, 0% 0%, 0% 42%, 52% 43%, 49.5% 64%, 47.5% 77%, 46% 77%, 44.5% 90%);
        clip-path: polygon(43.5% 100%, 98% 91%, 100% 27%, 95% 13%, 87% 3%, 22% 2%, 0% 0%, 0% 42%, 52% 43%, 49.5% 64%, 47.5% 77%, 46% 77%, 44.5% 90%);

    }

    #buch{
        position: absolute;
        left:50%;
        bottom: 55px * @ratio;

        margin-left: -120px * @ratioMove;

        width: 532px * @ratio;
        height: 315px * @ratio;

        z-index:5;

        transform: translateX(-100%);

        background: url("../assets/images/buch.png") center center no-repeat;
        background-size: cover;
    }

    #buecher{
        position: absolute;
        left:50%;
        bottom: 10px * @ratio;

        margin-left: -95px * @ratioMove;

        width: 701px * @ratio;
        height: 382px * @ratio;

        z-index:4;

        transform: translateX(-100%);

        background: url("../assets/images/buecher.png") center center no-repeat;
        background-size: cover;
    }

    #waage{
        position: absolute;
        left:50%;
        bottom: 118px * @ratio;

        margin-left: -400px * @ratioMove;

        width: 507px * @ratio;
        height: 222px * @ratio;

        z-index:3;

        transform: translateX(-100%);

        background: url("../assets/images/waage.png") center center no-repeat;
        background-size: cover;
    }

    #flasche-waage{
        position: absolute;
        left:50%;
        bottom: 330px * @ratio;

        margin-left: -725px * @ratioMove;

        width: 143px * @ratio;
        height: 231px * @ratio;

        z-index:4;

        transform: translateX(-100%);

        background: url("../assets/images/flasche-waage.png") center center no-repeat;
        background-size: cover;
    }

    #flasche-pine-tisch{
        position: absolute;
        left:50%;
        bottom: 98px * @ratio;

        margin-left: -220px * @ratioMove;

        width: 207px * @ratio;
        height: 322px * @ratio;

        z-index:5;

        background: url("../assets/images/flasche-pineapple-tisch.png") center center no-repeat;
        background-size: cover;

        -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 49% 89%, 0 36%, );
        clip-path: polygon(0 0, 100% 0, 100% 100%, 49% 89%, 0 36%, );

    }

    #flaschen-pine-regal{
        position: absolute;
        left:50%;
        top: 310px;

       margin-left: -192px;

        width: 214px;
        height: 84px;

        z-index:2;

        background: url("../assets/images/flaschen-pine-regal.png") center center no-repeat;
        background-size: cover;

    }

    #flaschen-regal{
        position: absolute;
        left:50%;
        top: 309px;

        margin-left: -192px;

        width: 786px;
        height: 126px;

        z-index:3;

        background: url("../assets/images/flaschen-regal-2.png") center center no-repeat;
        background-size: cover;

    }

    #flaschen-top-left{
        position: absolute;
        left:50%;
        top: 310px;

        margin-left: 85px;

        width: 220px;
        height: 79px;

        z-index: 2;

        background: url("../assets/images/flaschen-top-left.png") center center no-repeat;
        background-size: cover;

    }

    #flaschen-top-right{
        position: absolute;
        left:50%;
        top: 356px;

        margin-left: 414px;

        width: 181px;
        height: 79px;

        z-index:2;

        background: url("../assets/images/flaschen-top-right.png") center center no-repeat;
        background-size: cover;

    }

    .infobox{
        display: none;
        @height: 196px;
        position: absolute;

        height: 196px;

        z-index: -1;

        &.active{
            display: flex;
            z-index: 9;
        }

        &.left{

            .ib-content{
                order: 2;
            }

            .kr-line{
                order: 1;
                transform: rotateY(180deg);
            }

            .ib-title{
                display: flex;
                align-items: flex-end;
                line-height: 1;
            }
        }

        &.down{
            .ib-content{
                display: flex;
                align-items: flex-end ;
                justify-content: flex-end;
                flex-direction: column;

                .ib-title {
                    padding-top: 3px;
                    background: url("../assets/images/infobox-content-line.png") top left repeat-x;
                }
                .ib-inhalt{
                    padding-top: 0;
                    padding-bottom: 10px;
                }
            }
            .kr-line{
                transform: rotateX(180deg);
            }
        }

        .ib-content{
            width: 215px;

            .ib-title{
                display: flex;
                align-items: flex-end;

                height: 33px;
                width: 100%;
                padding-left: 10px;
                padding-right: 10px;

                font-family: 'docktrinregular';
                color:#fff;
                font-size: 28px;
                line-height: 1;


                background: url("../assets/images/infobox-content-line.png") bottom left repeat-x;
            }

            .ib-inhalt{
                padding-top: 10px;
                padding-left: 10px;
                padding-right: 10px;
                font-size: 16px;
                color: @themeColor2;
                font-family: 'Palatino-Roman';
            }
        }

        .kr-line{
            width: 157px;
            height: @height;

            background: url("../assets/images/infobox-kr-line.png") bottom left no-repeat;
        }
    }

    #infobox-kiste {
        bottom: 220px * @ratio;
        left:50%;
        margin-left: -100px * @ratioMove;
    }

    #infobox-glock {
        bottom: 200px * @ratio;
        left:50%;
        margin-left: -296px * @ratioMove;
    }

    #infobox-kasse {
        bottom: 350px * @ratio;
        left:50%;
        margin-left: 90px * @ratioMove;
    }

    #infobox-flasche-tisch {
        bottom: 300px * @ratio;
        left:50%;
        margin-left: -165px * @ratioMove;
    }

    #infobox-flasche-pine-tisch {
        bottom: 300px * @ratio;
        left:50%;
        margin-left: -165px * @ratioMove;
    }

    #infobox-buch {
        bottom: 220px * @ratio;
        left:50%;
        margin-left: -345px * @ratioMove;
    }

    #infobox-flasche-waage {
        bottom: 330px * @ratio;
        left:50%;
        margin-left: -735px * @ratioMove;

        .kr-line{
            width: 80px;
        }
    }

    #infobox-flaschen-regal {
        top: 410px * @ratio;
        left:50%;
        margin-left: -170px * @ratioMove;
    }


</style>
